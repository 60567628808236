<template>
  <div class="wrap">
    <div class="pur-top-right-icon">
      <i
        class="iconfont icon-wuliujiedian_xiadan"
        :class="'i-green'"
        v-if="!CANCEL || this.statusObj.UNCHKED"
      />
      <span
        :class="[currentIndex > 1 ? 'green' : 'gray']"
        v-if="!CANCEL || this.statusObj.UNCHKED"
      ></span>
      <i
        v-if="!CANCEL || this.statusObj.REVIEWCOMPLETED"
        class="iconfont icon-wuliujiedian_pingshen1"
        :class="[currentIndex >= 2 ? 'i-green' : 'i-gray']"
      />
      <span
        :class="[currentIndex > 2 ? 'green' : 'gray']"
        v-if="!CANCEL || this.statusObj.REVIEWCOMPLETED"
      ></span>
      <i
        v-if="!CANCEL || this.statusObj.WAITDELIVER"
        class="iconfont icon-wuliujiedian_wuliujiedan1"
        :class="[currentIndex >= 3 ? 'i-green' : 'i-gray']"
      />
      <span
        :class="[currentIndex > 3 ? 'green' : 'gray']"
        v-if="!CANCEL || this.statusObj.WAITDELIVER"
      ></span>
      <i
        v-if="!CANCEL || this.statusObj.WAITDELIVER"
        class="iconfont icon-wuliujiedian_tiaodupeiche1"
        :class="[currentIndex >= 4 ? 'i-green' : 'i-gray']"
      />
      <span
        :class="[currentIndex > 4 ? 'green' : 'gray']"
        v-if="!CANCEL || this.statusObj.WAITDELIVER"
      ></span>
      <i
        v-if="!CANCEL || this.statusObj.PARTCHECKED"
        class="iconfont icon-wuliujiedian_fayunchuku1"
        :class="[currentIndex >= 5 ? 'i-green' : 'i-gray']"
      />
      <span
        :class="[currentIndex > 5 ? 'green' : 'gray']"
        v-if="!CANCEL || this.statusObj.PARTCHECKED"
      ></span>
      <i
        v-if="!CANCEL || this.statusObj.CUSTSIGIN|| this.statusObj.CHECKED"
        class="iconfont icon-wuliujiedian_kehuqianshou1"
        :class="[currentIndex >= 6 ? 'i-green' : 'i-gray']"
      />
      <i
        v-if="CANCEL"
        class="iconfont icon-wuliujiedian_quxiao i-gray"
      />
    </div>
    <div class="pur-top-right-txt">
      <div v-if="!CANCEL || this.statusObj.UNCHKED">
        <p style="padding-right:4px">下单</p>
        <p v-if="currentIndex >= 1">{{  orderdetail.orderHeader&&formatDate(orderdetail.orderHeader.orderData) }}</p>
      </div>
      <div v-if="(!CANCEL || this.statusObj.ALREADYPLANPRODUCT)">
        <p :class="[currentIndex >= 2 ? '' : 'gray-p']">订单评审</p>
        <p v-if="currentIndex >= 2">{{ this.statusObj.ALREADYPLANPRODUCT }}</p>
      </div>
      <div v-if="!CANCEL || this.statusObj.LOGISTICS_TAKE_ORDER">
        <p :class="[currentIndex >= 3 ? '' : 'gray-p']">物流接单</p>
        <p v-if="currentIndex >= 3">{{ this.statusObj.LOGISTICS_TAKE_ORDER }}</p>
      </div>
      <div v-if="!CANCEL || this.statusObj.WAITDELIVER">
        <a-popover placement="bottom" v-if="orderdetail.dispatchCenter">
          <template slot="content">
            <p>{{orgConfigs.orgName}}</p>
            <template v-if="orgConfigs.orgConfigs && orgConfigs.orgConfigs.length > 0">
              <template v-for="(item, itemIndex) in orgConfigs.orgConfigs">
                <p>{{item.title}}</p>
                <template v-for="(child, childIndex) in item.contents">
                  <p>{{child}}</p>
                </template>
              </template>
            </template>
            <p v-else>物流17:00前接收的订单，将当天发货。17:00后接收的订单，将在次日17:00前发出。</p>
          </template>
          <template slot="title">
            <span>物流发运规则</span>
          </template>
          <p :class="[currentIndex >= 4 ? '' : 'gray-p']">调度配车<i style='color:#00AAA6;font-size: 12px;margin-left: 4px;' class="iconfont icon-wentitubiao"></i></p>
        </a-popover>
        <p :class="[currentIndex >= 4 ? '' : 'gray-p']" v-else>调度配车</p>
        <p v-if="currentIndex >= 4">{{ this.statusObj.DISPATCH_CAR }}</p>
      </div>
      <div v-if="!CANCEL || this.statusObj.PARTCHECKED">
        <p :class="[currentIndex >= 5 ? '' : 'gray-p']">发运出库</p>
        <p v-if="currentIndex >= 5">{{ this.statusObj.DELIVERY_OUT }}</p>
      </div>
      <div v-if="!CANCEL || this.statusObj.CUSTSIGIN">
        <p :class="[currentIndex >= 6 ? '' : 'gray-p']">客户签收</p>
        <p v-if="currentIndex >= 6">{{ this.statusObj.CUSTSIGIN||this.statusObj.CHECKED }}</p>
      </div>
      <div v-if="CANCEL">
        <p>取消</p>
        <p>{{ this.statusObj.CANCEL }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import dmsService from "@/utils/dmsrequest";
moment.locale("zh-cn");
export default {
  name: "Step",
  props: {
    orderdetail: {
      required: true,
      type: Object
    }
  },
  watch: {
    "orderdetail.statusInfos": {
      handler: function() {
        this.statusObj = {};
        if (this.orderdetail && this.orderdetail.statusInfos) {
          this.orderdetail.statusInfos.forEach(item => {
            this.statusObj[item.status] = item.date;
          });
        }
        if (this.statusObj.CANCEL) {
          this.CANCEL = true;
        } else {
          this.CANCEL = false;
        }
        if(this.orderdetail.dispatchCenter){
          dmsService({
            url: 'findOmsOrgConfigByOrgName',
            data: {
              orgName: this.orderdetail.dispatchCenter
            }
          }).then(res => {
            let result;
            if(typeof res.data == "string"){
              result = JSON.parse(res.data)
            }else{
              result = res.data;
            }
            this.orgConfigs = result.data;
          })
        }
      },
      immediate: true
    }
  },
  computed: {
    currentIndex: function() {
      let step = 1;
      if (this.orderdetail && this.orderdetail.statusInfos) {
        const flowArr = ['', 'ALREADYPLANPRODUCT', 'LOGISTICS_TAKE_ORDER', 'DISPATCH_CAR', 'DELIVERY_OUT', 'CHECKED']
        this.orderdetail.statusInfos.forEach(el => {
            const statusIndex = flowArr.findIndex(j => j == el.status);
            if(statusIndex > -1){
              step = statusIndex + 1;
            }
        });
        return step;
      } else {
        return step;
      }
    }
  },
  data() {
    return {
      statusObj: {},
      CANCEL: false,
      orgConfigs: {}
    };
  },
  methods:{
    formatDate(date){
      return moment(date).format('YYYY-MM-DD HH:mm:ss');
    }
  }
};
</script>

<style scoped lang="less">
.wrap {
  width: 930px;
  height: 139px;
  background: #fff;

  .pur-top-right-icon {
    text-align: left;
    padding-left: 68px;

    .iconfont {
      border-radius: 50%;
      width: 42px;
      height: 42px;
      line-height: 42px;
      font-size: 24px;
      position: relative;
      top: 8px;
      text-align: center;
      color: #fff;
    }

    .i-green {
      background: #20C6BA;
    }

    .i-gray {
      background: #CCCCCC;
    }

    img {
      width: 36px;
      height: 36px;
    }

    span {
      width: 94px;
      margin: 18px 8px 0;
    }

    .green {
      border-bottom: 1px dashed #00aaa6;
    }

    .gray {
      border-bottom: 1px dashed #cccccc;
    }
  }

  .pur-top-right-txt {
    margin: 16px 0 0 28px;
    :last-child {
      margin-right: 0px;
    }

    div {
      float: left;
      width: 120px;
      margin: 0 32px 0 0;

      p {
        font-size: 12px;
        font-weight: 500;
        color: #262626;
        line-height: 12px;
        margin-bottom: 7px;
      }
    }
    .gray-p{
      color: #aaa;
    }
  }
}
</style>
