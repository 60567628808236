import service from "@/utils/request";
import dmshttp from "@/utils/dmsrequest";
//获取订单详情
export function getOrderDetail(id) {
  return service({
    method: "get",
    url: "/order/orderLine.htm",
    params: {
      id: id
    }
  });
}

export function getImg(data) {
  return dmshttp({
    url: 'toOmsView',
    data
  })
}
// 取消整笔订单
export function cancleOrderAll(params) {
  return service({
    method: "post",
    url: "/order/cancelHeader.nd",
    params
  });
}
// 取消商品行
export function cancleOrderLine(params) {
  return service({
    method: "post",
    url: "/order/cancelLine.nd",
    params
  });
}